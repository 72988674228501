import {userOnboardingStepApi} from "../api/user-onboarding-step/index.js";
import React, {useCallback, useEffect, useState} from "react";
import {useRouter} from "../hooks/use-router.js";
import {paths} from "../paths.js";
import {stepCompletedEmitter} from "../utils/step-completed-emitter.js";
import Joyride from "react-joyride";
import toast from "react-hot-toast";
import {ebayApiCalls} from "../api/ebay";
import {RouterLink} from "./router-link.jsx";
import Link from "@mui/material/Link";
import {warehouseApi} from "../api/warehouse/index.js";


export const Onboarding = () => {
    const [stepTransition, setStepTransition] = useState(false);
    const [stepTransition2, setStepTransition2] = useState(false);

    const [steps, setSteps] = useState([
        {
            target: '.step-1',
            content: (
                <div>
                    <strong>Welcome to SellerSyncPro, with this guide we will show you how to sell on eBay</strong>
                    <br />
                    <br />
                    If you don't want to read you can stop by clicking on the cross on the right in the corner of the
                    tooltip window
                    <br />
                    <br />
                    Click on the button to go to your company page
                </div>
            ),
            stepId: 1,
            url: paths.dashboard.companyAccount,
            tab: 'create',
            styles: {
                spotlight: {
                    paddingRight: '240px',
                },
            },
            disableScrolling: true,
        },
        {
            target: '.step-2',
            content: (
                <div>
                    <strong>Logging into your eBay account.</strong>
                </div>
            ),
            stepId: 2,
            url: paths.dashboard.companyAccount,
            tab: 'general',
            styles: {
                spotlight: {
                    paddingRight: '240px',
                },
            },
            disableScrolling: true,
            // spotlightClicks: false
        },
        {
            target: '.step-3',
            content: (
                <div>
                    <strong>Click the eBay policy tab.</strong>
                </div>
            ),
            stepId: 3,
            url: paths.dashboard.companyAccount,
            tab: 'general',
            disableScrolling: true,
            // spotlightClicks: false
        },
        {
            target: '.step-4',
            content: (
                <div>
                    <strong>Add Payment Policy.</strong>
                </div>
            ),
            stepId: 4,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
            },
        },
        {
            target: '.step-5',
            content: (
                <div>
                    <strong>Fill in the required fields and save your changes.</strong>
                </div>
            ),
            stepId: 5,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
            },
        },
        {
            target: '.step-6',
            content: (
                <div>
                    <strong>Add Return Policy.</strong>
                </div>
            ),
            stepId: 6,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
                addBlockerOverlay();
            },
        },
        {
            target: '.step-7',
            content: (
                <div>
                    <strong>Fill in the required fields and save your changes.</strong>
                </div>
            ),
            stepId: 7,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
            },
        },
        {
            target: '.step-8',
            content: (
                <div>
                    <strong>Add Shipping(Fulfillment) Policy.</strong>
                    <br />
                    <br />
                    You will be redirected to your eBay account to create a policy
                </div>
            ),
            stepId: 8,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
            },
        },
        {
            target: '.step-9',
            content: (
                <div>
                    <strong>Click the eBay Warehouse Location tab.</strong>
                </div>
            ),
            stepId: 9,
            url: paths.dashboard.companyAccount,
            tab: 'ebayPolicy',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayPolicy'},
                    })
                );
            },
        },
        {
            target: '.step-10',
            content: (
                <div>
                    <strong>Add eBay Warehouse Location.</strong>
                </div>
            ),
            stepId: 10,
            url: paths.dashboard.companyAccount,
            tab: 'ebayLocation',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayLocation'},
                    })
                );
            },
        },
        {
            target: '.step-11',
            content: (
                <div>
                    <strong>Fill in the required fields and save your changes.</strong>
                </div>
            ),
            stepId: 11,
            url: paths.dashboard.companyAccount,
            tab: 'ebayLocation',
            disableScrolling: true,
            preStepAction: () => {
                window.dispatchEvent(
                    new CustomEvent('changeTab', {
                        detail: {tab: 'ebayLocation'},
                    })
                );
            },
        },
        {
            target: '.step-12',
            content: (
                <div>
                    <strong>Go to the upload/create goods page.</strong>
                </div>
            ),
            stepId: 12,
            url: paths.dashboard.companyAccount,
            // tab: 'create',
            tab: 'ebayLocation',
            styles: {
                spotlight: {
                    paddingRight: '240px',
                },
            },
            disableScrolling: true,
            // spotlightClicks: false
        },
        {
            target: '.step-13',
            content: (
                <div>
                    <strong>Create a new item.</strong>
                </div>
            ),
            stepId: 13,
            url: paths.dashboard.invoice,
            tab: null,
            disableScrolling: true,
            preStepAction: () => {
                toggleRunWithDelay();
            },
        },
        {
            target: '.step-14',
            content: (
                <div>
                    <strong>We will create a test listing with you for eBay with the product M&M'S Milk Chocolate Candy enter
                        this name in the Batch Item Name field and put the quantity 1.</strong>
                </div>
            ),
            stepId: 14,
            url: paths.dashboard.addItem,
            tab: null,
            disableScrolling: true,
            preStepAction: () => {
                toggleRunWithDelay();
            },
        },
        {
            target: '.step-15',
            content: (
                <div>
                    <strong>Go to edit the item.</strong>
                </div>
            ),
            stepId: 15,
            url: paths.dashboard.batchId('all'),
            tab: null,
            disableScrolling: true,
        },
        {
            target: '.step-16',
            content: (
                <div>
                    <strong>Upload 8 photos for the listing, you can download the finished photos at this</strong> <Link
                    color="text.warning"
                    component={RouterLink}
                    target="_blank"
                    href={paths.guidedListingImage}
                    variant="subtitle2"
                >
                    link
                </Link>
                </div>
            ),
            stepId: 16,
            url: '',
            tab: null,
            disableScrolling: true,
            preStepAction: () => {
                window.location.reload();
            },
        },
        {
            target: '.step-17',
            content: (
                <div>
                    <strong>Fill in all required fields and save your changes.</strong>
                </div>
            ),
            stepId: 17,
            url: '',
            tab: null,
            // disableScrolling: true,
        },
        {
            target: '.step-18',
            content: (
                <div>
                    <strong>Click on the link and you can view your eBay listing.</strong>
                </div>
            ),
            stepId: 18,
            url: paths.dashboard.listing,
            tab: null,
            disableScrolling: true,
        },
        {
            target: '.step-19',
            content: (
                <div>
                    <strong>Click to view listing details.</strong>
                </div>
            ),
            stepId: 19,
            url: paths.dashboard.listing,
            tab: null,
            disableScrolling: true,
        },
        {
            target: '.step-20',
            content: (
                <div>
                    <strong>Click the button to remove the listing from publication.</strong>
                </div>
            ),
            stepId: 20,
            url: paths.dashboard.listing,
            tab: null,
            // disableScrolling: true,
        },
        {
            target: '.step-21',
            content: (
                <div>
                    <strong>And the last step is to click on the button to remove the listing from eBay completely.</strong>
                </div>
            ),
            stepId: 21,
            url: paths.dashboard.listing,
            tab: null,
            // disableScrolling: true,
        },
    ]);
    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(null);
    const router = useRouter();

    const toggleRunWithDelay = () => {
        const stopRuntimeout = setTimeout(() => {
            setRun(false);
            removeBlockerOverlay();
        }, 2000);

        const setRuntimeout = setTimeout(() => {
            setRun(true);
        }, 3000);

        return () => {
            clearTimeout(stopRuntimeout);
            clearTimeout(setRuntimeout);
        };
    };

    useEffect(() => {
        return toggleRunWithDelay();
    }, []);

    useEffect(() => {
        const updateStepsOrNavigate = async () => {
            const firstInventoryItemId = localStorage.getItem('firstInventoryItemId');
            if (firstInventoryItemId) {
                setSteps((prevSteps) =>
                    prevSteps.map((step) =>
                        step.stepId === 17 || step.stepId === 16
                            ? { ...step, url: paths.dashboard.updateInv(firstInventoryItemId) }
                            : step
                    )
                );
            } else {
                await saveOnboardingProgress(12);
            }
        };

        updateStepsOrNavigate().catch((error) => {
            console.error('Error during onboarding steps update:', error);
        });
    }, []);


    const removeBlockerOverlay = () => {
        const blocker = document.getElementById('blocker-overlay');
        if (blocker) {
            document.body.removeChild(blocker);
        }
    };

    const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
    });

    const fetchOnboardingProgress = useCallback(async () => {
        try {
            setRun(false);
            const onboardingStatus = await userOnboardingStepApi.getOnboardingStatus();
            const responseLocation = await warehouseApi.getLocations();
            const { isOnboarded, currentStep } = onboardingStatus.data;

            if (window.location.pathname === paths.guidedListingImage) {
                removeBlockerOverlay();
                return;
            }

            if (isOnboarded) {
                removeBlockerOverlay();
                return;
            }

            let completedSteps = [];

            if (currentStep < 8) {
                const policies = await ebayApiCalls.ebayGetPolicy();
                const { paymentPolicy, returnPolicy, fulfillmentPolicy } = policies || {};

                if (paymentPolicy?.length) {
                    completedSteps.push(5);
                }
                if (returnPolicy?.length) {
                    completedSteps.push(7);
                }
                if (fulfillmentPolicy?.length) {
                    completedSteps.push(8);
                }

                for (const stepId of completedSteps) {
                    await userOnboardingStepApi.step({ currentStep: stepId });
                }
            }

            if (currentStep >= 8 && currentStep <= 10 || !responseLocation.data.length) {
                if (responseLocation?.data.length) {
                    completedSteps.push(11);
                } else {
                    completedSteps.push(8);
                }
                for (const stepId of completedSteps) {
                    await userOnboardingStepApi.step({ currentStep: stepId });
                }
            }

            const nextStep = Math.max(...completedSteps, currentStep);
            setCurrentStep(nextStep);

            if (nextStep < steps.length) {
                await navigateToStep(steps[nextStep]);
            } else {
                removeBlockerOverlay();
            }
        } catch (error) {
            console.error('Error during onboarding initialization or progress fetching:', error);
            removeBlockerOverlay();
        }
    }, [steps]);


    // Save the current step to the backend
    const saveOnboardingProgress = async (stepId) => {
        try {
            await userOnboardingStepApi.step({currentStep: stepId});
        } catch (error) {
            console.error('Error when saving onboarding progress:', error);
        }
    };

    useEffect(() => {
        if ((currentStep === 19 && !stepTransition) || (currentStep === 20 && !stepTransition2)) {
            waitForElementsAndExecute(
                ['.step-19'],
                ([step19Element]) => {
                    if (step19Element) {
                        step19Element.dispatchEvent(clickEvent);
                    }
                }
            );
        }
    }, [currentStep]);

    const navigateToStep = async (step) => {
        if (!step || !step.url) {
            return;
        }
        if (window.location.pathname === step.url && (!step.tab || window.currentTab === step.tab)) {
            return;
        }
        if (window.location.pathname !== step.url) {
            router.push(step.url);
        }
        if (step.tab) {
            window.dispatchEvent(
                new CustomEvent('changeTab', {
                    detail: {tab: step.tab},
                })
            );
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        if (step.preStepAction) {
            step.preStepAction();
        }

    };

    useEffect(() => {
        fetchOnboardingProgress();
        if (currentStep < steps.length) {
            navigateToStep(steps[currentStep]);
        }
    }, [fetchOnboardingProgress, currentStep, steps]);

    useEffect(() => {
        if (currentStep < steps.length) {
            setRun(true);
        }
    }, [currentStep, steps.length]);

    useEffect(() => {
        const handleTabChange = (event) => {
            const {tab} = event.detail;
            window.currentTab = tab;
        };

        window.addEventListener('changeTab', handleTabChange);

        return () => {
            window.removeEventListener('changeTab', handleTabChange);
        };
    }, []);

    const addBlockerOverlay = () => {
        let blocker = document.getElementById('blocker-overlay');
        if (!blocker) {
            blocker = document.createElement('div');
            blocker.id = 'blocker-overlay';
            blocker.style.position = 'fixed';
            blocker.style.top = 0;
            blocker.style.left = 0;
            blocker.style.width = '100%';
            blocker.style.height = '100%';
            blocker.style.zIndex = '9990';
            blocker.style.pointerEvents = 'all';
            document.body.appendChild(blocker);
        }
    };

    useEffect(() => {
        addBlockerOverlay();

        return () => {
            removeBlockerOverlay();
        };
    }, []);

    const [isDeliberateClose, setIsDeliberateClose] = useState(false);

    const handleJoyrideCallback = async (data) => {

        const {status, index, type, action, step} = data;

        if (action === "stop") {
            return;
        }

        if (action === "close" && isDeliberateClose) {
            console.log('aga')
            try {
                await userOnboardingStepApi.completed();
                setRun(false);
                removeBlockerOverlay();
                toast.success('You have successfully close Guide', {duration: 5000});
            } catch (error) {
                console.error('Error saving onboarding progress on close:', error);
            } finally {
                setIsDeliberateClose(false);
            }
            return;
        }

        const tooltipClose = document.querySelector('.react-joyride__tooltip button[aria-label="Close"]');
        if (tooltipClose) {
            tooltipClose.onclick = () => setIsDeliberateClose(true);
        }

        const toastId = 'onboarding-success';
        if (currentStep === index) {

            const currentStepCompleted = async () => {
                try {
                    await saveOnboardingProgress(steps[index].stepId);
                    setCurrentStep(index + 1);
                    await navigateToStep(steps[index + 1]);
                } catch (error) {
                    console.error('Error when completing the current step:', error);
                }
            };


            if (index === 0) {
                if (action === 'close' && type === 'step:after') {
                    await currentStepCompleted();
                }
            }

            if (index === 1) {
                if (type === 'error:target_not_found') {
                    toggleRunWithDelay();
                }
                waitForElementsAndExecute(
                    ['.step-2'],
                    ([step2Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            step2Element.dispatchEvent(clickEvent);
                        }
                    }
                );
            }

            if (index === 2) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-3'],
                    ([step1Element, step3Element]) => {
                        if ((step1Element instanceof SVGElement) && step3Element) {
                            step1Element.dispatchEvent(clickEvent);
                            if (action === 'close' && type === 'step:after') {
                                currentStepCompleted();
                            }
                        }
                    }
                );
            }

            if (index === 3) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-4'],
                    ([step1Element, step4Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                        if (action === 'close' && type === 'step:after') {
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 4) {
                waitForElementsAndExecute(
                    ['.step-4'],
                    ([step4Element]) => {
                        step4Element.dispatchEvent(clickEvent);
                    }
                );

                waitForElementsAndExecute(
                    ['.step-1', '.step-5'],
                    ([step1Element, step5Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                    }
                );
            }

            if (index === 5) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-6'],
                    ([step1Element, step6Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                        if (action === 'close' && type === 'step:after') {
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 6) {
                waitForElementsAndExecute(
                    ['.step-6'],
                    ([step6Element]) => {
                        step6Element.dispatchEvent(clickEvent);
                    }
                );
                waitForElementsAndExecute(
                    ['.step-1', '.step-7'],
                    ([step1Element, step7Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                    }
                );
            }

            if (index === 7) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-8'],
                    async ([step1Element, step8Element]) => {
                        step1Element.dispatchEvent(clickEvent);

                        if (action === 'close' && type === 'step:after') {
                            window.open('https://www.ebay.com/bp/ship/create', '_blank');

                            const pollingInterval = setInterval(async () => {
                                try {
                                    const response = await ebayApiCalls.ebayGetPolicy();

                                    if (response && !response.error) {
                                        const updatedPolicies = response;
                                        if (
                                            updatedPolicies.fulfillmentPolicy &&
                                            updatedPolicies.fulfillmentPolicy.some((policy) => policy.fulfillmentPolicyId)
                                        ) {
                                            clearInterval(pollingInterval);
                                            await currentStepCompleted();
                                        }
                                    } else {
                                        console.error('Error fetching policies:', response?.error);
                                    }
                                } catch (error) {
                                    console.error('Error refreshing and fetching policies:', error);
                                    clearInterval(pollingInterval);
                                }
                            }, 2000);

                            toggleRunWithDelay();
                        }
                    }
                );
            }

            if (index === 8) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-9'],
                    ([step1Element, step9Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                        if (action === 'close' && type === 'step:after') {
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 9) {
                waitForElementsAndExecute(
                    ['.step-1', '.step-10'],
                    ([step1Element, step10Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                        if (action === 'close' && type === 'step:after') {
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 10) {
                waitForElementsAndExecute(
                    ['.step-10'],
                    ([step10Element]) => {
                        step10Element.dispatchEvent(clickEvent);
                    }
                );
                waitForElementsAndExecute(
                    ['.step-1', '.step-11'],
                    ([step1Element, step11Element]) => {
                        step1Element.dispatchEvent(clickEvent);
                    }
                );
            }

            if (index === 11) {
                waitForElementsAndExecute(
                    ['.step-12'],
                    ([step12Element]) => {
                    }
                );
                if (action === 'close' && type === 'step:after') {
                    await currentStepCompleted();
                }
            }

            if (index === 12) {
                waitForElementsAndExecute(
                    ['.step-13'],
                    ([step13Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 13) {
                waitForElementsAndExecute(
                    ['.step-14'],
                    ([step13Element]) => {
                        // if (action === 'close' && type === 'step:after') {
                        //   currentStepCompleted();
                        // }
                    }
                );
            }

            if (index === 14) {
                if (type === 'error:target_not_found') {
                    toggleRunWithDelay();
                }

                waitForElementsAndExecute(
                    ['.step-15'],
                    ([step15Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            step15Element.dispatchEvent(clickEvent);
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 15) {
                if (action === 'close' && type === 'step:after') {
                }
            }

            if (index === 16) {
                if (action === 'close' && type === 'step:after') {
                }
            }

            if (index === 17) {
                if (type === 'error:target_not_found') {
                    toggleRunWithDelay();
                }
                waitForElementsAndExecute(
                    ['.step-18'],
                    ([step18Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            step18Element.dispatchEvent(clickEvent);
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 18) {
                waitForElementsAndExecute(
                    ['.step-19'],
                    ([step19Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            setStepTransition(true);
                            step19Element.dispatchEvent(clickEvent);
                            currentStepCompleted();
                        }
                    }
                );
            }

            if (index === 19) {
                waitForElementsAndExecute(
                    ['.step-20'],
                    ([step20Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            setStepTransition2(true);
                            step20Element.dispatchEvent(clickEvent);
                        }
                    }
                );
            }

            if (index === 20) {
                waitForElementsAndExecute(
                    ['.step-21'],
                    ([step21Element]) => {
                        if (action === 'close' && type === 'step:after') {
                            step21Element.dispatchEvent(clickEvent);
                        }
                    }
                );
            }

            if (status === 'finished' || status === 'skipped') {
                setRun(false);
                removeBlockerOverlay();
                return;
            }

            if (type === 'step:after') {
                return;
            }

            stepCompletedEmitter.on('stepCompleted', async ({stepIndex}) => {
                if (stepIndex === index) {
                    if (stepIndex === 20) {
                        await userOnboardingStepApi.completed();
                        setRun(false);
                        router.push(paths.dashboard.index);
                        if (!toast[toastId]) {
                            toast.success('You have successfully familiarized yourself with the SellerSyncPro service', {
                                id: toastId,
                            });
                        }
                    } else {
                        await currentStepCompleted();
                    }
                }
            });
        }
    };

    const waitForElementsAndExecute = (selectors, actionCallback) => {
        const waitForElement = () => {
            const elements = selectors.map(selector => document.querySelector(selector));
            const allElementsFound = elements.every(element => element);

            if (allElementsFound) {
                actionCallback(elements);
            } else {
                requestAnimationFrame(waitForElement);
            }
        };

        waitForElement();
    };

    useEffect(() => {
        const handleNavigation = async () => {
            const step = steps[currentStep];

            if (window.location.pathname === paths.guidedListingImage) {
                return;
            }

            if (step && (window.location.pathname !== step.url || (step.tab && window.currentTab !== step.tab))) {
                await navigateToStep(step);
            }
        };

        window.addEventListener('popstate', handleNavigation);
        window.addEventListener('changeTab', handleNavigation);

        return () => {
            window.removeEventListener('popstate', handleNavigation);
            window.removeEventListener('changeTab', handleNavigation);
        };
    }, [currentStep, steps]);


    useEffect(() => {
        const handleStartOnboarding = (event) => {
            const {startingStep} = event.detail;
            setCurrentStep(startingStep);
            setRun(true);
        };

        window.addEventListener('startOnboarding', handleStartOnboarding);

        return () => {
            window.removeEventListener('startOnboarding', handleStartOnboarding);
        };
    }, []);

    useEffect(() => {
        const pauseListener = async () => {
            await saveOnboardingProgress(3)
            removeBlockerOverlay();
            setRun(false);
        };

        const resumeListener = async () => {
            const response = await userOnboardingStepApi.getOnboardingStatus();
            const {isOnboarded, currentStep} = response.data;
            if (!isOnboarded) {
                setCurrentStep(currentStep);
                if (currentStep < steps.length) {
                    await navigateToStep(steps[currentStep]);
                }
            }
        };

        stepCompletedEmitter.on('pauseOnboarding', pauseListener);
        stepCompletedEmitter.on('resumeOnboarding', resumeListener);

        return () => {
            stepCompletedEmitter.off('pauseOnboarding', pauseListener);
            stepCompletedEmitter.off('resumeOnboarding', resumeListener);
            removeBlockerOverlay();
        };
    }, []);


    return (
        <div>
            {currentStep !== null && (
                <Joyride
                    steps={steps.map((step) => ({...step, disableBeacon: true}))}
                    run={run}
                    stepIndex={currentStep}
                    callback={handleJoyrideCallback}
                    continuous
                    spotlightClicks={false}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                        buttonNext: {
                            display: 'none',
                        },
                        buttonBack: {
                            display: 'none',
                        },
                        buttonSkip: {
                            display: 'none',
                        },
                        overlay: {
                            pointerEvents: 'none',
                        },
                    }}
                />
            )}
        </div>
    );
};
