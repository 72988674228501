import { api } from "../";

class EbayApi {
  constructor() {
    this.baseUrl = '/api/trade/ebay';
  }
  async ebayGetPolicy() {
    const response = await api.get(`${this.baseUrl}/ebay-get-policy`);

    return response.data;
  }

  async ebayListing(id, data) {
    return await api.put(`${this.baseUrl}/list-to-ebay/${id}`, data);
  }
  async ebayGetRefreshToken(request) {
    return await api.post(`${this.baseUrl}/ebay-sign-in`, request);
  }
  async getConditions(id) {
    const response = await api.get(`${this.baseUrl}/conditions/${id}`);
    return response.data;
  }
  async getAspectsInventory(id) {
    const response = await api.get(`${this.baseUrl}/aspects-inventory/${id}`);

    return response.data;
  }
  async getAspectsProduct(id) {
    const response = await api.get(`${this.baseUrl}/aspects-product/${id}`);

    return response.data;
  }

  async getAspectsByCategory(categoryId) {
    const response = await api.get(`${this.baseUrl}/aspects-by-category/${categoryId}`);

    return response.data;
  }

  async withdrawOffer(id) {

    return await api.put(`${this.baseUrl}/withdraw-offer/${id}`, null);
  }

  async publishOffer(id) {
    return await api.put(`${this.baseUrl}/publish-offer/${id}`, null);
  }

  async deleteOffer(id) {
    return await api.put(`${this.baseUrl}/delete-offer/${id}`, null);
  }

  async updateOfferPrice(id) {
    return await api.put(`${this.baseUrl}/update-offer/${id}`, null);
  }

  async updateInventoryItem(id) {
    return await api.put(`${this.baseUrl}/update-inventory/${id}`, null);
  }

  async sendShippingFulfillment(id) {
    return await api.post(`${this.baseUrl}/shipping-fulfillment/${id}`, null);
  }
}
export const ebayApiCalls = new EbayApi();
