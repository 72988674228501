import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import PropTypes from "prop-types";
import {memo, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from "@mui/material/Radio";
import Card from "@mui/material/Card";
import {LAST_ONBOARDING_STEP, START_GUIDE} from "../../utils/constants.js";
import {userOnboardingStepApi} from "../../api/user-onboarding-step/index.js";


const getStyle = (isMobile) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90%' : '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
    borderRadius: '10px',
});


export const OnboardingModal = memo((props) => {
    const {open, onClose, step} = props;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const style = getStyle(isMobile);

    const [selectedOption, setSelectedOption] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = async () => {
        if (!selectedOption) return;
        setIsSubmitting(true);
        try {
            await userOnboardingStepApi.startGuide({option: selectedOption});
            const startingStep = selectedOption === START_GUIDE.BEGIN ? 0 : step;
            window.dispatchEvent(new CustomEvent('startOnboarding', { detail: { startingStep } }));
            onClose();
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
        >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={onClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Stack
                        spacing={3}
                        sx={style}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                display: 'flex',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h4"
                                        sx={{mt: 2, flexGrow: 1, textAlign: 'center'}}>
                                Do you want to run the guide again? Select the available options
                            </Typography>
                            <IconButton onClick={onClose}
                                        sx={{ml: 'auto'}}>
                                <Close />
                            </IconButton>
                        </Stack>
                        <Stack spacing={3}>
                            <Card
                                key={START_GUIDE.BEGIN}
                                sx={{
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    p: 2,
                                    ...(selectedOption === START_GUIDE.BEGIN && {
                                        backgroundColor: 'primary.alpha12',
                                        boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`,
                                    }),
                                }}
                                onClick={() => handleOptionChange(START_GUIDE.BEGIN)}
                                variant="outlined"
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                >
                                    <Radio
                                        checked={selectedOption === START_GUIDE.BEGIN}
                                        color="primary"
                                    />
                                    <div>
                                        <Typography variant="subtitle1">Start Guide from Beginning</Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                        >
                                            Start the guide from the beginning
                                        </Typography>
                                    </div>
                                </Stack>
                            </Card>
                            {step !== LAST_ONBOARDING_STEP && (
                                <Card
                                    key={START_GUIDE.LAST}
                                    sx={{
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        p: 2,
                                        ...(selectedOption === START_GUIDE.LAST && {
                                            backgroundColor: 'primary.alpha12',
                                            boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`,
                                        }),
                                    }}
                                    onClick={() => handleOptionChange(START_GUIDE.LAST)}
                                    variant="outlined"
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Radio
                                            checked={selectedOption === START_GUIDE.LAST}
                                            color="primary"
                                        />
                                        <div>
                                            <Typography variant="subtitle1">Start Guide from Last Step</Typography>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Start the guide from where you left off.
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Card>
                            )}
                        </Stack>
                        <Stack
                            alignItems="center"
                            direction={isMobile ? "column" : "row"}
                            justifyContent="flex-end"
                            spacing={2}
                            sx={{
                                width: isMobile ? '100%' : 'auto',
                            }}
                        >
                            <Button
                                fullWidth={isMobile}
                                type="submit"
                                color="inherit"
                                variant="outlined"
                                onClick={onClose}
                                sx={{mr: 2}}
                            >
                                Close
                            </Button>
                            <Button
                                fullWidth={isMobile}
                                variant="outlined"
                                disabled={!selectedOption || isSubmitting}
                                onClick={handleSubmit}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Stack>
                    </Stack>
                </Fade>
            </Modal>
        </Dialog>
    )
});

OnboardingModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    step: PropTypes.number,
};
