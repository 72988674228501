export const PHOTO_API_URL = `${location.protocol}//${window.location.host}/images/`;
export const GUIDED_LISTING_PHOTO_API_URL = `${location.protocol}//${window.location.host}/images/guided/`;
export const EBAY_LINK = 'https://www.ebay.com/itm/';

export const PHOTO_DELETE = {
    PRODUCT: 'product',
    INVENTORY: 'inventory',
};

export const INVENTORY_ITEMS_STATUS = {
     NEW: 'new',
     PENDING: 'pending',
     ACTIVE: 'active',
     SOLD: 'sold',
     UNAVAILABLE: 'unavailable',
     AWAITING_SHIPMENT: 'awaiting shipment',
     SHIPPED: 'shipped',
     DELIVERED: 'delivered',
     LISTED: 'listed',
     WITHDRAWN_FROM_EBAY: 'withdrawn from ebay',
     DELETED_FROM_EBAY: 'deleted from ebay',
     WAITING_FOR_PENDING: 'waiting for pending',
};

export const INV_ITEM_PHOTOS_MAX_COUNT = 8;

export const ITN_MASK = 8000;

export const STEPPER = {
     IMPORT: 0,
     PRINT: 1,
     PHOTO: 2,
     INVENTORY: 3,
     LISTING: 4,
};

export const LENGTH_UNIT_OF_MEASURE_ENUM = [
     'INCH',
     'FEET',
     'CENTIMETER',
     'METER'
];

export const WEIGHT_UNIT_OF_MEASURE_ENUM = [
     'POUND',
     'KILOGRAM',
     'OUNCE',
     'GRAM'
];

export const CONTACT_PHONE = '+1-732-348-33-34';
export const CONTACT_EMAIL = 'cs@sellersyncpro.com';

export const EBAY_MARKETPLACE = {
     EBAY_US: 'EBAY_US'
};

export const POLICY_CATEGORY_TYPE_ENUM = {
     // MOTORS_VEHICLES: 'MOTORS_VEHICLES',
     ALL_EXCLUDING_MOTORS_VEHICLES: 'ALL_EXCLUDING_MOTORS_VEHICLES',
};

export const DOMESTIC_REFUND_METHOD = {
     MONEY_BACK: "Money back",
     REPLACEMENT: "Money back or replacement",
};

export const DOMESTIC_RETURN_SHIPPING_COST_PAYER = {
     BUYER: "Buyer",
     SELLER: "Free for buyer, you pay",
};

export const DOMESTIC_RETURN_PERIOD = {
     14: "14 Days",
     30: "30 Days",
     60: "60 Days",
};

export const INTERNATIONAL_REFUND_METHOD = {
     MONEY_BACK: "Money back",
     REPLACEMENT: "Money back or replacement",
};

export const INTERNATIONAL_RETURN_SHIPPING_COST_PAYER = {
     BUYER: "Buyer",
     SELLER: "Free for buyer, you pay",
};

export const INTERNATIONAL_RETURN_PERIOD = {
     14: "14 Days",
     30: "30 Days",
     60: "60 Days",
};

export const DOMESTIC_SHIPPING_COST_TYPE_ENUM = {
     FLAT_RATE: "Flat: same cost to all buyers",
     CALCULATED: "Calculated: Cost varies by buyer location",
     LOCAL_PICKUP: "No shipping: Local pickup only",
};

export const SHIPPING_OPTION_TYPE_ENUM = {
     DOMESTIC: "DOMESTIC",
     INTERNATIONAL: "INTERNATIONAL",
};

export const SHIPPING_HANDLING_TIME = {
     1: "1 business day",
     2: "2 business days",
     3: "3 business days",
     4: "4 business days",
     5: "5 business days",
     6: "6 business days",
     7: "7 business days",
     10: "10 business days",
     15: "15 business days",
     20: "20 business days",
     30: "30 business days",
     40: "40 business days",
};

export const FAQS = [
     {
          question: 'What is SellerSyncPro?',
          answer:
              'SellerSyncPro is a service designed to help eBay sellers efficiently list and manage used, open box, or near-new items. It allows the seller to create individual listings with unique photos and descriptions, ensuring customers see the exact item they are purchasing. This leads to faster sales, better prices, and fewer returns.',
     },
     {
          question: 'How does SellerSyncPro work?',
          answer:
              "SellerSyncPro simplifies the listing process in a few easy steps:\n" +
              "1.\tUpload Documents: Upload an invoice, manifest, or other documents received from the seller who sold you the item. Alternatively, manually add items by entering their names and quantities.\n" +
              "2.\tAssign Item Numbers: Assign a unique item number to each product. For best results, use Dymo printers to print barcodes. Next use barcode scanners to read them efficiently.\n" +
              "3.\tCreate Listings: Write detailed descriptions for each item and create individual listings.\n" +
              "4.\tManage Sales: Use a barcode reader to locate sold items ready for shipping.\n",
     },
     {
          question: 'What types of items can I list with SellerSyncPro?',
          answer:
              'You can list any used, open box, or near-new items. This includes electronics, clothing, accessories, household goods, and more.',
     },
     {
          question: 'Why should I use SellerSyncPro instead of bulk listing tools?',
          answer:
              "Unlike bulk listing tools, SellerSyncPro allows you to create unique listings for each item with personalized photos and descriptions. This ensures that customers see the exact item they are buying, which can lead to faster sales, premium pricing, and reduced returns.",
     },
     {
          question: 'Do I need special equipment to use SellerSyncPro?',
          answer:
              'While you can manage everything manually, using Dymo printers for barcodes and barcode scanners will significantly speed up the process. A smartphone or a computer with a camera is also essential for taking unique item photos.',
     },
     {
          question: 'Can I use SellerSyncPro if I don’t have an invoice or manifest?',
          answer:
              'Yes, you can manually add items by entering their names and quantities if you don’t have an invoice or manifest.',
     },
     {
          question: 'How do I take unique pictures for each item?',
          answer:
              'You can use your smartphone to take high-quality photos of each item, or you can upload photos from your computer. Ensure that each picture clearly shows the item’s condition and any distinguishing features.',
     },
     {
          question: 'How do I create effective item descriptions?',
          answer:
              'Focus on accurately describing the item\'s condition, features and any defects. Be honest and detailed to give buyers confidence in their purchase.',
     },
     {
          question: 'How does SellerSyncPro help with shipping?',
          answer:
              'SellerSyncPro integrates barcode scanning to help you quickly find sold items that are ready to be shipped. This streamlines your shipping process and reduces the chances of errors.',
     },
     {
          question: 'Is SellerSyncPro suitable for high-volume sellers?',
          answer:
              'Yes, SellerSyncPro is ideal for both small and high-volume sellers. The system is designed to handle multiple listings efficiently, especially when using barcodes and scanners.',
     },
     {
          question: 'Can I try SellerSyncPro before committing to a subscription?',
          answer:
              'Yes, the basic plan on SellerSyncPro is free to use. This allows you to get started without any initial commitment. Visit our website for more information on the features included in the basic plan and details on our subscription plans.',
     },
     {
          question: 'How do I get started with SellerSyncPro?',
          answer:
              'Getting started is easy! Sign up on our website, follow the step-by-step setup guide, and begin uploading your items. Our support team is also available to help you with any questions you may have.\n' +
              'For more information or assistance, feel free to contact our customer support team. We’re here to help you optimize your eBay listings and boost your sales!\n',
     },
];

export const INVITATION_ACTION = {
     EXPIRED: 'expired',
     LOGIN: 'login',
     REGISTER: 'register',
};

export const CONFIRMATION_EMAIL = {
     EXPIRED: 'expired',
     SENT: 'sent',
};

export const MARKETPLACE_CONDITION = {
     5: "New",
     13: "Used",
};

export const CHECK_MARKETPLACE_FILE = {
     TITLE: "Inventory title (required)",
     DESCRIPTION: "Description (required)",
     QUANTITY: "Quantity (required)",
};

export const MARKETPLACE_PARSER_TYPE = {
     TEMPLATE: "template",
     MAPPING: "mapping",
}

export const CLARITY_PROJECT = 'ng1ee3giuv';

export const POLICY_TYPES = {
  PAYMENT: "Payment Policy",
  RETURN: "Return Policy",
  FULFILLMENT: "Fulfillment Policy",
};

export const LAST_ONBOARDING_STEP = 20;

export const START_GUIDE = {
     BEGIN: "BEGIN",
     LAST: "LAST"
}
