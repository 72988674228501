import { api } from '../';

class WarehouseItemApi {
  constructor() {
    this.baseUrl = '/api/trade/warehouse';
  }

  async create(request) {
    return await api.post(`${this.baseUrl}`, request);
  }

  async getLocations() {
    return await api.get(`${this.baseUrl}`);
  }

  async deleteLocation(token, request) {

    return await api.delete(`${this.baseUrl}`, {data: request});
  }
}

export const warehouseApi = new WarehouseItemApi();
