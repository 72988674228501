import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';

import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02';
import BarChartSquare02Icon from 'src/icons/untitled-ui/duocolor/bar-chart-square-02';
import Building04Icon from 'src/icons/untitled-ui/duocolor/building-04';
import CalendarIcon from 'src/icons/untitled-ui/duocolor/calendar';
import CheckDone01Icon from 'src/icons/untitled-ui/duocolor/check-done-01';
import CreditCard01Icon from 'src/icons/untitled-ui/duocolor/credit-card-01';
import CurrencyBitcoinCircleIcon from 'src/icons/untitled-ui/duocolor/currency-bitcoin-circle';
import File01Icon from 'src/icons/untitled-ui/duocolor/file-01';
import GraduationHat01Icon from 'src/icons/untitled-ui/duocolor/graduation-hat-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02';
import LineChartUp04Icon from 'src/icons/untitled-ui/duocolor/line-chart-up-04';
import Lock01Icon from 'src/icons/untitled-ui/duocolor/lock-01';
import LogOut01Icon from 'src/icons/untitled-ui/duocolor/log-out-01';
import Mail03Icon from 'src/icons/untitled-ui/duocolor/mail-03';
import Mail04Icon from 'src/icons/untitled-ui/duocolor/mail-04';
import MessageChatSquareIcon from 'src/icons/untitled-ui/duocolor/message-chat-square';
import ReceiptCheckIcon from 'src/icons/untitled-ui/duocolor/receipt-check';
import Share07Icon from 'src/icons/untitled-ui/duocolor/share-07';
import ShoppingBag03Icon from 'src/icons/untitled-ui/duocolor/shopping-bag-03';
import ShoppingCart01Icon from 'src/icons/untitled-ui/duocolor/shopping-cart-01';
import Truck01Icon from 'src/icons/untitled-ui/duocolor/truck-01';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import XSquareIcon from 'src/icons/untitled-ui/duocolor/x-square';
import {tokens} from 'src/locales/tokens';
import {paths} from 'src/paths';
import {menuSettings} from "../../config";
import ProductListIcon from "../../icons/untitled-ui/duocolor/product-list";
import ScanInventoryItemsIcon from "../../icons/untitled-ui/duocolor/scan-inventory";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {hashApi} from "../../api/hash";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PrintIcon from "@mui/icons-material/Print";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useDispatch, useSelector} from "../../store";
import {thunks} from "../../thunks/mobile-url";
import {useAuth} from "../../hooks/use-auth.js";
import {roles} from "../../utils/user-roles.js";

export const useSections = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const currentCompany = useSelector(state => state.companyRoles.primaryCompany);
    const url = useSelector((state) => state.mobilePhotoUrl.url);
    const { includeRoles } = useAuth();

    useEffect(() => {
        if (!url &&  currentCompany && currentCompany.companyId) {
            dispatch(thunks.fetchUrl(currentCompany.companyId));
        }
    }, [dispatch, url, currentCompany?.companyId]);

    return useMemo(() => {
        // if (menuSettings.env !== 'prod') {
        if (menuSettings.env === 'prod') {
            return [
                {
                    subheader: t(tokens.nav.mainFlow),
                    items: [
                        {
                            title: t(tokens.nav.addItem),
                            path: paths.dashboard.addItem,
                            button: true
                        },
                        {
                            title: t(tokens.nav.dataManagerImportBatch),
                            path: paths.dashboard.invoice,
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                  className="step-12"
                                >
                                    <FileUploadIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerItn),
                            path: paths.dashboard.batchId('all'),
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                >
                                    <PrintIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerTakePhoto),
                            path: url,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <AddAPhotoIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerDescribeInventory),
                            path: paths.dashboard.describeInventoryItem,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Inventory2Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerListing),
                            path: paths.dashboard.listing,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LocalGroceryStoreIcon />
                                </SvgIcon>
                            ),
                        }
                    ]
                },
                {
                    subheader: t(tokens.nav.pages),
                    items: [
                        {
                            title: t(tokens.nav.companyAccount),
                            path: paths.dashboard.companyAccount,
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                  className="step-1"
                                >
                                    <HomeSmileIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerProduct),
                            path: paths.dashboard.productList,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ProductListIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.dataManagerDashboardInvInfo),
                            path: paths.dashboard.inventoryInfo,
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                >
                                    <DashboardIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.scanInventoryItem),
                            path: paths.dashboard.scanInventoryItem,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ScanInventoryItemsIcon />
                                </SvgIcon>
                            ),
                        }
                    ]
                },
                {
                    subheader: t(tokens.nav.information),
                    items: [
                        {
                            title: t(tokens.nav.information),
                            path: paths.dashboard.information.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.privacyPolicy),
                                    path: paths.dashboard.information.privacyPolicy,
                                },
                                {
                                    title: t(tokens.nav.termsService),
                                    path: paths.dashboard.information.termsService,
                                },
                                {
                                    title: t(tokens.nav.aboutUs),
                                    path: paths.dashboard.information.aboutUs,
                                },
                                {
                                    title: t(tokens.nav.faq),
                                    path: paths.dashboard.information.faq,
                                },
                            ],
                        }
                    ]
                },
                includeRoles([roles.ROLE_SUPER_ADMIN]) &&
                {
                    subheader: t(tokens.nav.admin),
                    items: [
                        {
                            title: t(tokens.nav.pages),
                            path: paths.dashboard.information.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.demoRequest),
                                    path: paths.admin.demoRequestsDashboard,
                                },
                                {
                                    title: t(tokens.nav.emailDemoRequest),
                                    path: paths.admin.emailDemoRequestsDashboard,
                                },
                                {
                                    title: t(tokens.nav.clarityList),
                                    path: paths.admin.clarityList,
                                },
                                {
                                    title: t(tokens.nav.adminUserList),
                                    path: paths.admin.userList,
                                },
                                {
                                    title: t(tokens.nav.adminSellerList),
                                    path: paths.admin.sellerList,
                                },
                                {
                                    title: t(tokens.nav.adminProducts),
                                    path: paths.admin.productList,
                                },
                                {
                                    title: t(tokens.nav.adminOffers),
                                    path: paths.admin.offerList,
                                },
                                {
                                    title: t(tokens.nav.adminChat),
                                    path: paths.admin.chat,
                                },
                            ],
                        }
                    ]
                },
                includeRoles([roles.ROLE_MARKETPLACE, roles.ROLE_SUPER_ADMIN]) &&
                {
                    subheader: t(tokens.nav.marketplace),
                    items: [
                        {
                            title: t(tokens.nav.pages),
                            path: paths.dashboard.information.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.marketplaceImport),
                                    path: paths.marketplace.import,
                                },
                                {
                                    title: t(tokens.nav.marketplaceBatchItems),
                                    path: paths.marketplace.batchId('all'),
                                },
                                {
                                    title: t(tokens.nav.marketplaceDescribeInventory),
                                    path: paths.marketplace.describeInventoryItem,
                                },
                                {
                                    title: t(tokens.nav.marketplaceProduct),
                                    path: paths.marketplace.productList,
                                },
                            ],
                        }
                    ]
                },
            ];
        } else {
            return [
                {
                    items: [
                        {
                            title: t(tokens.nav.overview),
                            path: paths.dashboard.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <HomeSmileIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.analytics),
                            path: paths.dashboard.analytics,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <BarChartSquare02Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.ecommerce),
                            path: paths.dashboard.ecommerce,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LineChartUp04Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.crypto),
                            path: paths.dashboard.crypto,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <CurrencyBitcoinCircleIcon />
                                </SvgIcon>
                            ),
                            label: (
                                <Chip
                                    color="primary"
                                    label="New"
                                    size="small"
                                />
                            ),
                        },
                        {
                            title: t(tokens.nav.account),
                            path: paths.dashboard.account,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <HomeSmileIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.companyAccount),
                            path: paths.dashboard.companyAccount,
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                  // className="step-1"
                                >
                                    <HomeSmileIcon />
                                </SvgIcon>
                            ),
                        },
                    ],
                },
                {
                    subheader: t(tokens.nav.concepts),
                    items: [
                        {
                            title: t(tokens.nav.dataManager),
                            path: paths.dashboard.index,
                            icon: (
                                <SvgIcon
                                  fontSize="small"
                                >
                                    <LogOut01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.dataManagerImportBatch),
                                    path: paths.dashboard.invoice
                                },
                                {
                                    title: t(tokens.nav.dataManagerItn),
                                    path: paths.dashboard.batchId('all')
                                },
                                {
                                    title: t(tokens.nav.dataManagerProduct),
                                    path: paths.dashboard.productList
                                },
                                {
                                    title: t(tokens.nav.dataManagerListing),
                                    path: paths.dashboard.listing
                                },
                                {
                                    title: t(tokens.nav.scanInventoryItem),
                                    path: paths.dashboard.scanInventoryItem
                                },
                                {
                                    title: t(tokens.nav.dataManagerDescribeInventory),
                                    path: paths.dashboard.describeInventoryItem,
                                },
                                {
                                    title: t(tokens.nav.marketplaceImport),
                                    path: paths.marketplace.import,
                                },
                                {
                                    title: t(tokens.nav.marketplaceBatchItems),
                                    path: paths.marketplace.batchId('all'),
                                },
                                {
                                    title: t(tokens.nav.marketplaceDescribeInventory),
                                    path: paths.marketplace.describeInventoryItem,
                                },
                                {
                                    title: t(tokens.nav.marketplaceProduct),
                                    path: paths.marketplace.productList,
                                },
                                {
                                    title: t(tokens.nav.dataManagerDashboardInvInfo),
                                    path: paths.dashboard.inventoryInfo,
                                    icon: (
                                        <SvgIcon fontSize="small">
                                            <DashboardIcon />
                                        </SvgIcon>
                                    ),
                                },
                            ],
                        },
                        includeRoles([roles.ROLE_SUPER_ADMIN]) && {
                            title: t(tokens.nav.admin),
                            path: paths.dashboard.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LogOut01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.pages),
                                    path: paths.dashboard.information.index,
                                    icon: (
                                        <SvgIcon fontSize="small">
                                            <Users03Icon />
                                        </SvgIcon>
                                    ),
                                    items: [
                                        {
                                            title: t(tokens.nav.demoRequest),
                                            path: paths.admin.demoRequestsDashboard,
                                        },
                                        {
                                            title: t(tokens.nav.emailDemoRequest),
                                            path: paths.admin.emailDemoRequestsDashboard,
                                        },
                                        {
                                            title: t(tokens.nav.clarityList),
                                            path: paths.admin.clarityList,
                                        },
                                        {
                                            title: t(tokens.nav.adminUserList),
                                            path: paths.admin.userList,
                                        },
                                        {
                                            title: t(tokens.nav.adminSellerList),
                                            path: paths.admin.sellerList,
                                        },
                                        {
                                            title: t(tokens.nav.adminProducts),
                                            path: paths.admin.productList,
                                        },
                                        {
                                            title: t(tokens.nav.adminOffers),
                                            path: paths.admin.offerList,
                                        },
                                        {
                                            title: t(tokens.nav.adminChat),
                                            path: paths.admin.chat,
                                        },
                                    ],
                                }
                            ]
                        },
                        {
                            title: t(tokens.nav.customers),
                            path: paths.dashboard.customers.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Users03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.list),
                                    path: paths.dashboard.customers.index,
                                },
                                {
                                    title: t(tokens.nav.details),
                                    path: paths.dashboard.customers.details,
                                },
                                {
                                    title: t(tokens.nav.edit),
                                    path: paths.dashboard.customers.edit,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.productList),
                            path: paths.dashboard.products.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ShoppingBag03Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.list),
                                    path: paths.dashboard.products.index,
                                },
                                {
                                    title: t(tokens.nav.create),
                                    path: paths.dashboard.products.create,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.orderList),
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ShoppingCart01Icon />
                                </SvgIcon>
                            ),
                            path: paths.dashboard.orders.index,
                            items: [
                                {
                                    title: t(tokens.nav.list),
                                    path: paths.dashboard.orders.index,
                                },
                                {
                                    title: t(tokens.nav.details),
                                    path: paths.dashboard.orders.details,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.invoiceList),
                            path: paths.dashboard.invoices.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <ReceiptCheckIcon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.list),
                                    path: paths.dashboard.invoices.index,
                                },
                                {
                                    title: t(tokens.nav.details),
                                    path: paths.dashboard.invoices.details,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.logistics),
                            path: paths.dashboard.logistics.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Truck01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.dashboard),
                                    path: paths.dashboard.logistics.index,
                                },
                                {
                                    title: t(tokens.nav.fleet),
                                    path: paths.dashboard.logistics.fleet,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.academy),
                            path: paths.dashboard.academy.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <GraduationHat01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.dashboard),
                                    path: paths.dashboard.academy.index,
                                },
                                {
                                    title: t(tokens.nav.course),
                                    path: paths.dashboard.academy.courseDetails,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.jobList),
                            path: paths.dashboard.jobs.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Building04Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.browse),
                                    path: paths.dashboard.jobs.index,
                                },
                                {
                                    title: t(tokens.nav.details),
                                    path: paths.dashboard.jobs.companies.details,
                                },
                                {
                                    title: t(tokens.nav.create),
                                    path: paths.dashboard.jobs.create,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.socialMedia),
                            path: paths.dashboard.social.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Share07Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.profile),
                                    path: paths.dashboard.social.profile,
                                },
                                {
                                    title: t(tokens.nav.feed),
                                    path: paths.dashboard.social.feed,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.blog),
                            path: paths.dashboard.blog.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LayoutAlt02Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.postList),
                                    path: paths.dashboard.blog.index,
                                },
                                {
                                    title: t(tokens.nav.postDetails),
                                    path: paths.dashboard.blog.postDetails,
                                },
                                {
                                    title: t(tokens.nav.postCreate),
                                    path: paths.dashboard.blog.postCreate,
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.fileManager),
                            path: paths.dashboard.fileManager,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Upload04Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.kanban),
                            path: paths.dashboard.kanban,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <CheckDone01Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.mail),
                            path: paths.dashboard.mail,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Mail03Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.chat),
                            path: paths.dashboard.chat,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <MessageChatSquareIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.calendar),
                            path: paths.dashboard.calendar,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <CalendarIcon />
                                </SvgIcon>
                            ),
                        },
                    ],
                },
                {
                    subheader: t(tokens.nav.pages),
                    items: [
                        {
                            title: t(tokens.nav.auth),
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Lock01Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.login),
                                    items: [
                                        {
                                            title: 'Classic',
                                            path: paths.authDemo.login.classic,
                                        },
                                        {
                                            title: 'Modern',
                                            path: paths.authDemo.login.modern,
                                        },
                                    ],
                                },
                                {
                                    title: t(tokens.nav.register),
                                    items: [
                                        {
                                            title: 'Classic',
                                            path: paths.authDemo.register.classic,
                                        },
                                        {
                                            title: 'Modern',
                                            path: paths.authDemo.register.modern,
                                        },
                                    ],
                                },
                                {
                                    title: t(tokens.nav.forgotPassword),
                                    items: [
                                        {
                                            title: 'Classic',
                                            path: paths.authDemo.forgotPassword.classic,
                                        },
                                        {
                                            title: 'Modern',
                                            path: paths.authDemo.forgotPassword.modern,
                                        },
                                    ],
                                },
                                {
                                    title: t(tokens.nav.resetPassword),
                                    items: [
                                        {
                                            title: 'Classic',
                                            path: paths.authDemo.resetPassword.classic,
                                        },
                                        {
                                            title: 'Modern',
                                            path: paths.authDemo.resetPassword.modern,
                                        },
                                    ],
                                },
                                {
                                    title: t(tokens.nav.verifyCode),
                                    items: [
                                        {
                                            title: 'Classic',
                                            path: paths.authDemo.verifyCode.classic,
                                        },
                                        {
                                            title: 'Modern',
                                            path: paths.authDemo.verifyCode.modern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            title: t(tokens.nav.pricing),
                            path: paths.pricing,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <CreditCard01Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.checkout),
                            path: paths.checkout,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LogOut01Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.contact),
                            path: paths.contact,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Mail04Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: t(tokens.nav.error),
                            icon: (
                                <SvgIcon fontSize="small">
                                    <XSquareIcon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: '401',
                                    path: paths.notAuthorized,
                                },
                                {
                                    title: '404',
                                    path: paths.notFound,
                                },
                                {
                                    title: '500',
                                    path: paths.serverError,
                                },
                            ],
                        },
                    ],
                },
                {
                    subheader: 'Misc',
                    items: [
                        {
                            title: 'Level 0',
                            icon: (
                                <SvgIcon fontSize="small">
                                    <AlignLeft02Icon />
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: 'Level 1a',
                                    items: [
                                        {
                                            title: 'Level 2a',
                                            items: [
                                                {
                                                    title: 'Level 3a',
                                                },
                                                {
                                                    title: 'Level 3b',
                                                    disabled: true,
                                                },
                                            ],
                                        },
                                        {
                                            title: 'Level 2b',
                                        },
                                    ],
                                },
                                {
                                    title: 'Level 1b',
                                },
                            ],
                        },
                        {
                            title: 'Disabled',
                            disabled: true,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <XSquareIcon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: 'Label',
                            icon: (
                                <SvgIcon fontSize="small">
                                    <File01Icon />
                                </SvgIcon>
                            ),
                            label: (
                                <Chip
                                    color="primary"
                                    label="New"
                                    size="small"
                                />
                            ),
                        },
                        {
                            title: 'Blank',
                            path: paths.dashboard.blank,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <File01Icon />
                                </SvgIcon>
                            ),
                        },
                        {
                            title: 'External Link',
                            path: '/',
                            external: true,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <File01Icon />
                                </SvgIcon>
                            ),
                        },
                    ],
                },
            ];
        }

    }, [t, url]);

};
