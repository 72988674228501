import { api } from '../';

class UserOnboardingStepApi {
  constructor() {
    this.baseUrl = '/api/trade/onboarding';
  }

  async getOnboardingStatus() {
    return await api.get(`${this.baseUrl}/status`);
  }

  async step(request) {
    return await api.post(`${this.baseUrl}/step`, request);
  }

  async completed() {
    return await api.put(`${this.baseUrl}/completed`, null);
  }

  async startGuide(request) {
    return await api.post(`${this.baseUrl}/start-guide`, request);
  }
}

export const userOnboardingStepApi = new UserOnboardingStepApi();
